import { render, staticRenderFns } from "./sideBarBusinessMobile.vue?vue&type=template&id=49c69987&scoped=true&"
import script from "./sideBarBusinessMobile.vue?vue&type=script&lang=js&"
export * from "./sideBarBusinessMobile.vue?vue&type=script&lang=js&"
import style0 from "./sideBarBusinessMobile.vue?vue&type=style&index=0&id=49c69987&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "49c69987",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSheet } from 'vuetify/lib/components/VSheet';
installComponents(component, {VAvatar,VBtn,VCol,VIcon,VImg,VRow,VSheet})
