<template>
  <router-link
      v-if="!(!$store.state.dashboard.displayPageNavigation || pageNavigationFiltered.length < 2)"
      :to="businessDashLink($store.state.dashboard.pageNavigationReturnRoute, admin)"
      class="noUnderline"
  >
    <v-sheet color="transparent" height="40" v-ripple style="cursor: pointer" class="d-flex align-center" >
      <v-icon :color="wsACCENT" class="ml-6 mr-4">mdi-chevron-left</v-icon>
      <h5 v-if="sideMenu || SM"  :style="`color : ${wsACCENT}`">{{  $t('Back')  }}</h5>
    </v-sheet>
  </router-link>
</template>

<script>
import {mapState} from "vuex";

export default {
  name: "menuReturnButton",
  props : {
    pageNavigationFiltered : {},
    admin : {},
  },
  computed : {
    ...mapState('dashboard', ['sideMenu' ]),
  }
}
</script>



<style scoped>

</style>